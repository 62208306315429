import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Input } from '@components/Input';
import { SelectField } from '@components/Select/SelectField.tsx';

import customAxios from '@utils/customAxios.tsx';

export const ServicesConveyancingPage = () => {
    type Address = {
      line1: string,
      line2: string,
      city: string,
      postcode: string,
      tenureType: string,
      country: string,
      numberOfOwners: number
    };
    const [quotes, setQuotes] = useState([] as { name: string, fees: Fee[] }[]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const postcodeRegExp =
      /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;

    const quoteTypeEnum = z.enum(['Sale', 'Purchase', 'Both'], { errorMap: () => ({ message: 'Choose quote type' }) });
    const tenureTypeEnum = z.enum(['Unknown', 'Leasehold', 'Freehold'], { errorMap: () => ({ message: 'Choose tenure type' }) });
    type Conveyance = {
      quoteType: z.infer<typeof quoteTypeEnum>,
      tenureType: z.infer<typeof tenureTypeEnum>,
      // saleAddress: { line1: '', line2: '', city: '', postcode: '', tenureType: string },
      purchaseDetails: {
        address: Address,
        numberOfOwners: string;
        price: number;
        isRightToBuy: boolean;
        isLimitedCompany: boolean;
        isUsingMortgage: boolean;
        isHMO: boolean;
      },
    }
    type ConveyanceRequest = Omit<Conveyance, 'quoteType'> & {
      quoteType: 'SALE' | 'PURCHASE' | 'SALE_AND_PURCHASE'
    }
    const {
      register,
      handleSubmit,
      getValues,
      formState: { errors, isValid },
    } = useForm<Conveyance>({
      mode: 'onBlur',
      resolver: zodResolver(
        z.object({
          quoteType: quoteTypeEnum,
          tenureType: tenureTypeEnum,
          // saleAddress: z.object({
          //   line1: z.string().min(1, 'Address line 1 required'),
          //   line2: z.string().optional(),
          //   city: z.string().min(1, 'City is required'),
          //   county: z.string().optional(),
          //   postcode: z
          //     .string()
          //     .min(1, 'Moving from postcode is required')
          //     .regex(postcodeRegExp, 'Invalid postcode'),
          // }),
          purchaseDetails: z.object({
            address: z.object({
              line1: z.string().min(1, 'Address line 1 required'),
              line2: z.string().optional(),
              city: z.string().min(1, 'City is required'),
              country: z.string().optional(),
              postcode: z
                .string()
                .min(1, 'Moving from postcode is required')
                .regex(postcodeRegExp, 'Invalid postcode'),
            }),
            numberOfOwners: z.string().min(1, 'Choose number of owners')
              .transform(s => Number(s))
              .refine((val) => !isNaN(val), { message: "Must be a number" }),
            price: z.string().min(5)
              .transform(s => Number(s.replace(",", "")))
              .refine((val) => !isNaN(val), { message: "Must be a number" }),
            isRightToBuy: z.string().transform(s => Boolean(s)),
            isLimitedCompany: z.string().transform(s => Boolean(s)),
            isUsingMortgage: z.string().transform(s => Boolean(s)),
            isHMO: z.string().transform(s => Boolean(s)),
          }),
        }),
      ),
      defaultValues: {
        quoteType: undefined,
        tenureType: undefined,
        purchaseDetails: {
          address: { line1: '', line2: '', city: '', postcode: '' },
          numberOfOwners: undefined,
          price: 0,
          isRightToBuy: false,
          isLimitedCompany: false,
          isUsingMortgage: false,
          isHMO: false,
        },
      },
    });

    useEffect(() => {
      console.log(errors, isValid, getValues());
    }, [errors, isValid, getValues]);

    const onSubmit = async (data: Conveyance) => {
      setIsSubmitting(true);
      const quoteType: 'Sale' | 'Purchase' | 'Both' = data.quoteType;
      const transformedQuote = quoteType === 'Both' ? 'SALE_AND_PURCHASE' : quoteType.toUpperCase();
      const body = {
        ...data,
        quoteType: transformedQuote as 'SALE' | 'PURCHASE' | 'SALE_AND_PURCHASE',
      } satisfies ConveyanceRequest;
      await generateQuotes(body);
      setIsSubmitting(false);
    };

    return (
      <div>
        <header className="mx-auto my-4 flex w-full max-w-3xl flex-col items-center justify-center gap-8 px-5 pb-12 pt-8">
          <h2 className="flex items-baseline gap-2 text-center text-3xl font-medium md:text-4xl">
            Get a conveyancing quote
          </h2>
          <p className="text-center">
            Home can help find the best conveyance for your needs.
          </p>
        </header>
        <main className="box-border w-full px-5 pb-20 pt-8 md:pt-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center">
              <div className="col-span-4 flex flex-col gap-y-7">
                <SelectField
                  styles="w-full"
                  options={[
                    { key: 'Quote Type', value: 'Quote type', disabled: true },
                    { key: 'Sale', value: 'Sale' },
                    { key: 'Purchase', value: 'Purchase' },
                    { key: 'Both', value: 'Both' },
                  ]}
                  id="quoteType"
                  label="Quote type"
                  required
                  error={errors.quoteType}
                  {...register('quoteType')} />
                <Input
                  id="movingFrom.line1"
                  label="Address Line 1"
                  placeholder="Address line 1"
                  required
                  error={errors.purchaseDetails?.address?.line1}
                  {...register('purchaseDetails.address.line1')}
                />
                <Input
                  id="purchaseDetails.address.line2"
                  label="Address Line 2"
                  placeholder="Address line 2"
                  error={errors.purchaseDetails?.address?.line2}
                  {...register('purchaseDetails.address.line2')}
                />
                <Input
                  id="purchaseDetails.address.city"
                  label="City"
                  placeholder="City"
                  required
                  error={errors.purchaseDetails?.address?.city}
                  {...register('purchaseDetails.address.city')}
                />
                <Input
                  id="purchaseDetails.address.postcode"
                  label="Postcode"
                  placeholder="Postcode"
                  required
                  error={errors.purchaseDetails?.address?.postcode}
                  {...register('purchaseDetails.address.postcode')}
                />
                <SelectField
                  options={[
                    { key: 'England', value: 'England' },
                    { key: 'Wales', value: 'Wales' },
                    { key: 'Ireland', value: 'Ireland' },
                    { key: 'Scotland', value: 'Scotland' },
                  ]}
                  styles={'w-full'}
                  id="purchaseDetails.address.country"
                  label="Country"
                  required
                  error={errors.purchaseDetails?.address?.country}
                  {...register('purchaseDetails.address.country')}
                />
                <SelectField
                  styles="w-full"
                  options={[
                    { key: 'Tenure type', value: 'Tenure type', disabled: true },
                    { key: 'Leasehold', value: 'Leasehold' },
                    { key: 'Freehold', value: 'Freehold' },
                    { key: 'Unknown', value: 'Unknown' },
                  ]}
                  id="tenureType"
                  label="Tenure type"
                  required
                  error={errors.tenureType}
                  {...register('tenureType')} />
                <SelectField
                  options={[
                    { key: 'No. Owners', value: '1', disabled: true },
                    { key: '1', value: '1' },
                    { key: '2', value: '2' },
                    { key: '3', value: '3' },
                    { key: '4', value: '4' },
                  ]}
                  styles={'w-full'}
                  id="purchaseDetails.numberOfOwners"
                  label="Number of owners"
                  required
                  error={errors.purchaseDetails?.numberOfOwners}
                  {...register('purchaseDetails.numberOfOwners')}
                />
                <Input
                  id="purchaseDetails.price"
                  label="Price"
                  placeholder="Price"
                  required
                  error={errors.purchaseDetails?.price}
                  {...register('purchaseDetails.price')}
                  type="number"
                />
                <SelectField
                  options={[
                    { key: 'false', value: 'false' },
                    { key: 'true', value: 'true' },
                  ]}
                  styles={'w-full'}
                  id="purchaseDetails.isRightToBuy"
                  label="Right to buy"
                  required
                  error={errors.purchaseDetails?.isRightToBuy}
                  {...register('purchaseDetails.isRightToBuy')}
                />
                <SelectField
                  options={[
                    { key: 'false', value: 'false' },
                    { key: 'true', value: 'true' },
                  ]}
                  styles={'w-full'}
                  id="purchaseDetails.isLimitedCompany"
                  label="Limited company"
                  required
                  error={errors.purchaseDetails?.isLimitedCompany}
                  {...register('purchaseDetails.isLimitedCompany')}
                />
                <SelectField
                  options={[
                    { key: 'false', value: 'false' },
                    { key: 'true', value: 'true' },
                  ]}
                  styles={'w-full'}
                  id="purchaseDetails.isUsingMortgage"
                  label="Mortgage"
                  required
                  error={errors.purchaseDetails?.isUsingMortgage}
                  {...register('purchaseDetails.isUsingMortgage')}
                />
                <SelectField
                  options={[
                    { key: 'false', value: 'false' },
                    { key: 'true', value: 'true' },
                  ]}
                  styles={'w-full'}
                  id="purchaseDetails.isHMO"
                  label="HMO"
                  required
                  error={errors.purchaseDetails?.isHMO}
                  {...register('purchaseDetails.isHMO')}
                />
                <button
                  className="rounded-xl bg-primary px-8 py-3 text-xl text-white transition-all hover:enabled:bg-gray-700 disabled:opacity-50 cursor-pointer"
                  disabled={!isValid || isSubmitting}
                  hidden={quotes.length > 0}
                  type="submit"
                >
                  Quote me!
                </button>
              </div>
            </div>
          </form>

          <div className="mx-auto box-border w-full max-w-6xl mt-10">
            <div className="flex w-full flex-col items-center justify-center gap-10 md:gap-14">
              {quotes.map((q, index) => (
                <div className="flex flex-col" key={`quote-${index}`}>
                  <div className={'w-[80vw] md:w-[400px] bg-amber-200 rounded p-4'}>
                    <div className="text-lg">{q.name}</div>
                    <div className="flex flex-col gap-y-1">
                      {q.fees.map(f => (
                        <div className={'flex justify-between'}>
                          <div className={'self-start'}>{f.name}:</div>
                          <div className={'self-end'}>£{f.netValue}</div>
                        </div>
                      ))}
                      <div className={'flex justify-between'}>
                        <div className={'self-start'}>Total:</div>
                        <div className={'self-end'}>£{q.fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    );

    async function generateQuotes(body: ConveyanceRequest) {
      const res = await customAxios('/api/home/conveyancing/quote', {
        method: 'POST',
        withCredentials: true,
        data: body,
      });
      const data = await res.data as { quotes: QuotesData | null, sessionId: string | null };
      if ('quotes' in data && data.quotes != null) {
        console.log(data.quotes);
        setQuotes(data.quotes.quotes.flatMap(it => it.conveyancingQuotes.map(q => {
          const name = it.conveyancerBranch.name;
          const fees = q.legalTransactionFees.map(f => f);
          return ({ name, fees });
        }))?.sort((a, b) => {
          return a.fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0)
            - b.fees.map(f => f.netValue).reduce((tot, n) => tot += n, 0);
        }));
      }
      if (data.sessionId != null) {
        const sessionId = data.sessionId as string;
        console.log({ session: sessionId });
        setTimeout(() => getQuotes(sessionId), 10_000);
      }
    }

    async function getQuotes(sessionId: string) {
      const res = await customAxios(`/api/home/conveyancing/quote?session-id=${sessionId}`, {
        method: 'GET',
        withCredentials: true,
      });
      const data = await res.data as QuotesData | null;
      console.log('fetched');
      if (data != null && typeof data === 'object' && 'quotes' in data) {
        console.log(data.quotes);
      }
    }


  }
;


type CompanyAddress = {
  'subBuildingNumber': null,
  'subBuildingName': null,
  'buildingNumber': null,
  'buildingName': null,
  'thoroughfare': null,
  'line1': null,
  'line2': null,
  'line3': null,
  'line4': null,
  'locality': null,
  'townOrCity': null,
  'county': null,
  'district': null,
  'postcode': null,
  'country': null,
  'latitude': null,
  'longitude': null
};
type Fee = {
  'name': string | 'Fixed Legal Fees',
  'netValue': number | 850,
  'vatValue': number | 170,
  'grossValue': number | 1020
};
type Quote = {
  'legalTransactionFees': Fee[],
  'estimatedStampDuty': 0,
  'instructionDate': null,
  'referralDate': null,
  'id': string | 'f2cc02b0-428e-4042-af70-ad540c708786',
  'quoteType': 1
};
type Trustpilot = {
  'id': number | 59,
  'rating': number | 0,
  'reviews': number | 0,
  'url': string | 'https://www.trustpilot.com/review/thorntonjones.co.uk'
};
type RegulatoryBody = {
  'id': string | '815236',
  'name': string | 'SRA',
  'logo': string | 'https://assets.nonprod-txn.yourkeys.com/regulators/SRALogo.png',
  'logoUrl': null,
  'url': string | 'https://www.sra.org.uk/consumers/register/organisation/?sraNumber=815236'
};
type Company = {
  'id': number | 0,
  'name': string | 'Thornton Jones Solicitors Ltd',
  'logo': string | 'https://assets.nonprod-txn.yourkeys.com/conveyancers/SRA-815236.png',
  'address': CompanyAddress,
  'phoneNumber': string | null,
  'email': string | null,
  'url': string | 'www.thorntonjones.co.uk',
  'trustpilot': Trustpilot,
  'regulatoryBody': RegulatoryBody,
  'regulatoryId': number | 815236
};
type ConveyancerBranch = {
  'company': Company,
  'id': 9971,
  'name': string | 'Thornton Jones Solicitors Ltd',
  'logo': null,
  'address': CompanyAddress,
  'phoneNumber': string | '01924 290029',
  'iovoxPhoneNumber': string | null,
  'email': string | 'emily@thorntonslegal.com',
  'enquiryEmail': string | null,
  'url': string | 'www.thorntonjones.co.uk'
};
type BranchAndQuotes = {
  'conveyancerBranch': ConveyancerBranch,
  'conveyancingQuotes': Quote[],
  'instructionDate': null,
  'referralDate': null
};

type QuotesData = {
  'quoteGroupId': string | '81cb1b16-b1fa-44e9-8114-589cb5280061',
  'quotes': BranchAndQuotes[],
  'quotesExpired': boolean,
  'canInstruct': boolean,
  'instructionDate': null
};
;
